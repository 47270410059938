<template>
  <img :src="titleImgSrc" alt="" class="list-card-image" />
  <div class="main-content-container">
    <h1>{{ title }}</h1>
    <h3 class="category">{{ listCategory }}</h3>
    <h3>{{ subtitle }}</h3>
    <p>
      {{ projectGoalDescription }}
    </p>
     <p>
      {{ projectGoalDescription2 }}
    </p>
    <div v-for="item in textSection">
      <h3>{{ item.title }}</h3>
      <p>{{ item.body }}</p>
    </div>
    <h3>{{ listTitle }}</h3>
    <ul class="custom-list" v-for="item in listContent">
      <li>
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: [
    "titleImgSrc",
    "title",
    "listCategory",
    "projectGoalDescription",
    "projectGoalDescription2",
    "textSection",
    "listTitle",
    "listContent",
    "optionalImgSrc",
    "subtitle",
    "link",
    "linkName",
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.main-content-container {
  font-size:18px;
  @include bg-color(takeda-white);
  @include padding(10);
  border-radius: 8px 8px 8px 8px;
  margin-top: -10%;
  @include responsive(phone) {
    @include padding(5 4 5 4);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.arrow {
  margin-left: 10px;
}

.list-card-image {
  @include border-radius(2);
  max-width: 95%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.list-card-optional-image {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

h1 {
  font-size: 42px;
}

h3 {
  font-size: 24px;
}

h1,
h3 {
  color: $font-color-2;
  margin-top: 3%;
}

.custom-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  height: auto;
}

.custom-list li {
  background: url("../assets/images/list-card-bullet.svg") no-repeat left
    top;
  min-height: 60px;
  padding-left: 44px;
  padding-top: 2px;
  font-size: 18px;
}

.category {
  @include color(takeda-medium-gray) font-size: 28px;
  @include margin(2 0 4 0);
}
</style>