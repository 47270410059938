<template>
  <div class="flex-container rectangleCard-container">
    <div class="flex-6 img-container" v-if="flipImage">
      <img :src="imgSrc" alt="Slalom White Logo" class="card-image" />
    </div>
    <div class="flex-6 content">
        <h3 class = "subtitle">{{ subtitle }}</h3>
        <h1>{{ title }}</h1>
        <h3 class = "date">{{ date }}</h3>
        <p> {{ description }} </p>
        <a style="text-decoration: none" :href="link" v-if="firstFour">
            Read More
            <img class ="arrow" src='@/assets/images/next-arrow-blue.svg' />
        </a>
    </div>
    <div class="flex-6 img-container" v-if="!flipImage" >
      <img :src="imgSrc" alt="Slalom White Logo" class="card-image"/>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "title",
    "subtitle",
    "date",
    "description",
    "link",
    "imgSrc",
    "flipImage",
    "firstFour",
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

h1 {
  @include color(takeda-dark-red)
}

.date {
  @include color(takeda-medium-gray);
  font-size: 18px;
  margin-bottom: 10px;

}

.subtitle {
  @include color(takeda-dark-red);
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 10px;

}

p {
  font-weight: 300;
}

a {
  display: flex;
  text-align: center;
  @include color(takeda-dark-red);
  font-weight: 600;
  font-size: 18px;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  @include margin(-20 0 -20 0);
  @include responsive(phone) {
    @include margin(0);
    min-width: 0;
    min-height: 0;
    max-width: 100%;
  }
  @include responsive(tablet) {
    @include margin(0);
    min-width: 0;
    min-height: 0;
    max-width: 100%;
  }
}

.card-image {
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
   box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  @include responsive(phone) {
    position:static;
    width:100%;
  }
  @include responsive(tablet) {
    position:static;
  }
}

.content {
    @include responsive(phone) {
    @include padding(0 4 0 4);
  }
  @include responsive(tablet) {
    @include padding(0);
  }
}

.arrow {
  margin-left: 10px;
}

.rectangleCard-container {
  @include bg-color(takeda-white);
  @include padding(8);
  border-bottom-right-radius: 4em;
  position: relative;

  @include responsive(phone) {
    @include margin(4 1 4 1);
    @include padding(4 0 4 0);
  }
  @include responsive(tablet) {
    @include margin(5 5 5 5);
    @include padding(5 5 5 5);
  }
}

</style>
