<template>
   <div class="main-content-container">
     <ul class="custom-list" v-for="item in listContent">
      <li>
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: [
    "titleImgSrc",
    "title",
    "listCategory",
    "projectGoalDescription",
    "projectGoalDescription2",
    "textSection",
    "listTitle",
    "listContent",
    "optionalImgSrc",
    "subtitle",
    "link",
    "linkName",
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.main-content-container {
  font-size:18px;
  @include bg-color(takeda-white);
  margin-top: -10%;  
  @include padding(0 0 0 0);
  @include responsive(phone) {
  }
  @include responsive(tablet) {
  }
}

.list-card-image {
  max-width: 95%;
  display: block;
  margin-left: 50;
  margin-right: auto;
}

.list-card-optional-image {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.custom-list {
  list-style-type: none;
  padding: 0;
  height: auto;
}

.custom-list li {
  background:  no-repeat left
    top;
  margin-left: 150px;
  font-size: 18px;
}

.category {
  @include color(takeda-medium-gray) font-size: 28px;
  @include margin(2 0 2 0);
}
</style>
