<template>
  <img :src="titleImgSrc" alt="" class="list-card-image" />
  <div class="main-content-container">
    <h1>{{ title }}</h1>
    <h3 class="category">{{ date }}</h3>
    <h3 class="formatted-title">
      {{ formattedTitle }}
    </h3>
    <p>
      {{ description }}
    </p>
    <h3 class="list-title">{{ listTitle }}</h3>
    <ul class="custom-list" v-for="item in listContent">
      <li>
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: [
    "titleImgSrc",
    "title",
    "date",
    "formattedTitle",
    "description",
    "listTitle",
    "listContent",
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.main-content-container {
  @include bg-color(takeda-white);
  @include padding(10);
  border-radius: 8px 8px 56px 8px;
  margin-top: -5%;
  @include responsive(phone) {
    @include padding(5 4 5 4);
  }
  @include responsive(tablet) {
    @include padding(10 5 10 5);
  }
}

.list-card-image {
  @include border-radius(2);
  max-width: 95%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 42px;
}

h1,
h3 {
  @include color(takeda-dark-red);
  margin-top: 3%;
}

.custom-list {
  padding-left: 2%;
}

.category {
  @include color(takeda-medium-gray);
  @include margin(2 0 4 0);
}

.list-title {
  @include color(takeda-dark-red);
  font-size: 28px;
}

.formatted-title {
    @include color(takeda-light-pink);
    @include margin(4 0 4 0);
    font-size: 24px;
    line-height: 28.8px;
    border-left: 5px solid #F78C85;
    padding-left: 24px;
}
</style>
