<template>
<Alert :isActive="isError" v-if="isError">{{errorMessage}}</Alert>
<Loading :isShow="isLoading" v-if="!isError">
  <Navigation></Navigation>
  <div class="theme-container">
    <PageTitle
      :title="content['page-title']"
      :imgSrc="images['header3.svg']"
    ></PageTitle>
    <div class="description-container">
      <p> {{content['section-2-description']}}</p>
    </div>
    <div v-for="event in events">
      <div class="section-container"> 
        <EventCard
          :title="event.title"
          :subtitle="event.subtitle"
          :date="event.date"
          :description="event.description"
          :imgSrc="event.imgSrc"
          :link="event.link"
          :flipImage="event.flipImage"
          :firstFour="event.firstFour"
        />
      </div>
    </div>
    <div class="section-container-no-bg">
      <Card
        :title="content['section-3-subsection-1-title']"
        :content="content['section-3-description-1']"
        :imgSrc="images['footer-photo.png']"
        :flipImage="true"
      />
    </div>
  </div>
  <BottomNavigator :order='3'/>
</Loading>
</template>

<script>
import BottomNavigator from "@/components/BottomNavigator.vue"
import Card from "@/components/Card.vue";
import FeaturedEventCard from "@/components/FeaturedEventCard.vue";
import EventCard from "@/components/EventCard.vue";
import Navigation from "@/components/Navigation.vue";
import PageTitle from "@/components/PageTitle.vue";
import Loading from '@/components/Loading.vue';
import Alert from '@/components/Alert.vue';
import api from '@/services/api.js';

export default {
  data: () => ({
		content: {},
		images: {},
		isLoading: false,
		isError: false,
		errorMessage: '',
    events: [],
	}),

	methods: {
		async getData() {
			this.isError = false;
			this.isLoading = true;
			try {
				const { data } = await api.getContent('special-events');
				this.content = data.content;
				this.images = data.images;
        for (let i = 1; i < 20; i++) {
          if (`event-${i}-title-2` in this.content) {
            var event = {
              title: this.content[`event-${i}-title-2`],
              subtitle: this.content[`event-${i}-title-1`],
              date: this.content[`event-${i}-date`],
              description: this.content[`event-${i}-description-short`],
              flipImage: (i % 2 == 0 ? true : false),
              firstFour: (i < 5 ? true : false),
              link: `/special-events/all-events/${i - 1}`,
              imgSrc: `${this.images[`event-${i}-photo3.png`]}`
            };
            this.events.push(event);
          } else {
            break
          }
        }
			} catch (err) {
				this.isError = true;
				this.errorMessage = err.message;
			} finally {
				this.isLoading = false;
			}
		}
	},

	async beforeRouteUpdate(to, from, next) {
		await this.getData();
		next();
	},

	async created() {
		await this.getData();
	},

  components: {
    BottomNavigator,
    EventCard,
    Navigation,
    PageTitle,
    Loading,
    Card,
    FeaturedEventCard,
    Alert,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.theme-container {
  margin-bottom: 5%;
  @include relative;
  :deep(.page-title) {
    .title-container{
      @include bg-color( #134FAD);
    }
  }
}

.section-container {
  @include padding(14 10 14 10 );
  @include bg-color(takeda-light-grey);
  margin-top: -20px;
  z-index: 1;
  @include responsive(phone) {
    @include padding(2 1 2 1);
  }
  @include responsive(tablet) {
    @include padding(4 5 4 5);
  }
}

.no-bottom-padding{
  @include padding(5 10 0 10 );
  @include responsive(phone) {
    @include padding(0 1 0 1);
  }
  @include responsive(tablet) {
    @include padding(0 5 0 5);
  }
}

.section-container-no-bg {
  @include padding(14 10 14 10 );
  z-index: 1;

  @include responsive(phone) {
    @include padding(2 1 2 1);
  }
  @include responsive(tablet) {
    @include padding(4 5 4 5);
  }
  :deep(h1)  {
    color: $font-color-1;
  }
}

.description-container {
  @include padding(10 10 2 10);
  @include bg-color(takeda-light-grey);
  margin-top: -20px;
  z-index: 1;
    @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(4 5 4 5);
  }
}

h1 {
  @include color(takeda-dark-red);
  font-size: 42px;
}

</style>
