<template class="template">
  <Alert :isActive="isError" v-if="isError">{{errorMessage}}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
  <Navigation></Navigation>
    <div class = "theme-container">
      <PageTitle
        :title="content['page-title']"
        :imgSrc="images['header2.svg']"
      ></PageTitle>
      <div class="section-container top">
        <ListCard
          :listCategory="content['section-1-subtitle']"
          :subtitle="content['section-1-subsection-1-title']"
          :project-goal-description="content['section-1-subsection-1-description']"
          :list-title="content['section-1-subsection-2-title']"
        />
        <CardGrid
          :cards="[
            {
              title: content['section-1-card-1-title'],
              subtitle: content['section-1-card-1-description'],
            },
            {
              title: content['section-1-card-2-title'],
              subtitle: content['section-1-card-2-description'],
            },
            {
              title: content['section-1-card-3-title'],
              subtitle: content['section-1-card-3-description'],
            },
          ]"
        />
        <ListCard
          :list-title="content['section-1-subsection-3-title']"
          :listContent="[
            content['section-1-subsection-2-item-1'],
            content['section-1-subsection-2-item-2'],
            content['section-1-subsection-2-item-3'],
            content['section-1-subsection-2-item-4'],
            content['section-1-subsection-2-item-5'],
            content['section-1-subsection-2-item-6']
          ]"

        />
      </div>
      <div class="sectionContainerTitle">
        <h1 class="main-title" v-html="sectionOne.title">
        </h1>
         <div class="descriptionCards">
          <div class="flex-row">
            <template v-for="card in sectionOne.cards">
              <div class="flex-6 descriptionCard">
                <SingleCard :title="card.contentTitle" :subtitleBold="card.subtitleBold" :contentText="card.description" />
              </div>
            </template>
          </div>
        </div>
          <div class="guidingValuesCards">
            <div class="flex-row justify-content-center align-items-stretch">
              <template v-for="card in guidingValuesCards">
                <div class="flex-3 guidingValuesCard">
                <SingleCard :subtitleBold="card.title" :contentText="card.paragraph" :imgSrc=[card.imgSrc] :listContent="card.listContent"/>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="section-container">
        <ListCard
          :titleImgSrc="images['section-8-header-photo1.png']"
          :subtitle="content['section-8-subsection-1-title']"
          :title="content['section-8-title']"
          :project-goal-description="content['section-8-subsection-1-description']"
          :list-title="content['section-8-subsection-2-title']"
          :listContent="[
            content['section-8-subsection-2-item-1'],
            content['section-8-subsection-2-item-2'],
            content['section-8-subsection-2-item-3'],
            content['section-8-subsection-2-item-4']
          ]"
        />
        <CardGrid
          :cards="[
            {
              title: content['section-8-card-1-title'],
              subtitle: content['section-8-card-1-description'],
            },
            {
              title: content['section-8-card-2-title'],
              subtitle: content['section-8-card-2-description'],
            },
            {
              title: content['section-8-card-3-title'],
              subtitle: content['section-8-card-3-description'],
            },
             {
              title: content['section-8-card-4-title'],
              subtitle: content['section-8-card-4-description'],
            },
          ]"
        />
      </div>
      <div class="section-container">
        <ListCard
          :titleImgSrc="images['section-7-header-photo1.png']"
          :subtitle="content['section-7-subsection-1-title']"
          :title="content['section-7-title']"
          :project-goal-description="content['section-7-subsection-1-description']"
          :list-title="content['section-7-subsection-2-title']"
          :listContent="[
            content['section-7-subsection-2-item-1'],
            content['section-7-subsection-2-item-2'],
            content['section-7-subsection-2-item-3'],
            content['section-7-subsection-2-item-4']
          ]"
        />
        <CardGrid
          :cards="[
            {
              title: content['section-7-card-1-title'],
              subtitle: content['section-7-card-1-description'],
            },
            {
              title: content['section-7-card-2-title'],
              subtitle: content['section-7-card-2-description'],
            },
            {
              title: content['section-7-card-3-title'],
              subtitle: content['section-7-card-3-description'],
            },
             {
              title: content['section-7-card-4-title'],
              subtitle: content['section-7-card-4-description'],
            },
          ]"
        />
      </div>
      <div class="section-container">
        <ListCard
          :titleImgSrc="images['section-2-header-photo1.png']"
          :subtitle="content['section-2-subsection-1-title']"
          :title="content['section-2-title']"
          :project-goal-description="content['section-2-subsection-1-description']"
          :list-title="content['section-2-subsection-2-title']"
          :listContent="[
            content['section-2-subsection-2-item-1'],
            content['section-2-subsection-2-item-2'],
            content['section-2-subsection-2-item-3'],
            content['section-2-subsection-2-item-4'],
            content['section-2-subsection-2-item-5']
          ]"
        />
      </div>
      <div class="section-container">
        <ListCard
          :titleImgSrc="images['section-6-header-photo1.png']"
          :subtitle="content['section-6-subsection-1-title']"
          :title="content['section-6-title']"
          :project-goal-description="content['section-6-subsection-1-description']"
          :list-title="content['section-6-subsection-2-title']"
          :listContent="[
            content['section-6-subsection-2-item-1'],
            content['section-6-subsection-2-item-2'],
            content['section-6-subsection-2-item-3'],
            content['section-6-subsection-2-item-4']
          ]"
        />
      </div>
      <div class="section-container">
        <ListCardNoButtomPadding
          :titleImgSrc="images['section-4-header-photo1.png']"
          :subtitle="content['section-4-subsection-1-title']"
          :title="content['section-4-title']"
          :project-goal-description="content['section-4-subsection-1-description']"
          :list-title="content['section-4-subsection-2-title']"
          :listContent="[
            content['section-4-subsection-2-item-1'],
            content['section-4-subsection-2-item-3']
          ]"
        />
        <br>
         <SubList
            :listContent="[
            content['section-4-subsection-2-item-3-1'] ,
             content['section-4-subsection-2-item-3-2'] ,
              content['section-4-subsection-2-item-3-3'] ,
               content['section-4-subsection-2-item-3-4'] 
            ]"
        />
        <ListCardNoTitle
          :listContent="[
            content['section-4-subsection-5-title'],
          ]"
        />
        <br>
         <SubList
            :listContent="[
            content['section-4-subsection-4-item-1'],
            content['section-4-subsection-4-item-2'],
            content['section-4-subsection-4-item-3'],
            content['section-4-subsection-4-item-4'],
            content['section-4-subsection-4-item-5'],
            ]"
        />
        <ListCardNoTitle
          :listContent="[
            content['section-4-subsection-4-title'],
          ]"
        />
        <br>
          <SubList
            :listContent="[
            content['section-4-subsection-3-item-1'],
            ]"
        /> 
      </div>
      <div class="section-container">
        <ListCard
          :titleImgSrc="images['section-3-header-photo1.png']"
          :subtitle="content['section-3-subsection-1-title']"
          :title="content['section-3-title']"
          :project-goal-description="content['section-3-subsection-1-description']"
          :list-title="content['section-2-subsection-2-title']"
          :listContent="[
            content['section-3-subsection-2-item-1'],
            content['section-3-subsection-2-item-2'],
            content['section-3-subsection-2-item-3'],
            content['section-3-subsection-2-item-4'],
          ]"
        />
      </div>
      <div class="section-container">
        <ListCard
          :titleImgSrc="images['section-9-header-photo1.png']"
          :subtitle="content['section-9-subsection-1-title']"
          :title="content['section-9-title']"
          :project-goal-description="content['section-9-subsection-1-description']" 
          :project-goal-description2="content['section-9-subsection-2-description']"
          :list-title="content['section-9-subsection-2-title']"
          :listContent="[
            content['section-9-subsection-2-item-1'],
            content['section-9-subsection-2-item-2'],
            content['section-9-subsection-2-item-3'],
          ]"
        />
      </div>
       <div class="section-container">
        <ListCard
          :titleImgSrc="images['section-5-header-photo1.png']"
          :subtitle="content['section-5-subsection-1-title']"
          :title="content['section-5-title']"
          :project-goal-description="content['section-5-subsection-1-description']"
          :list-title="content['section-5-subsection-2-title']"
          :listContent="[
            content['section-5-subsection-2-item-1'],
            content['section-5-subsection-2-item-2'],
            content['section-5-subsection-2-item-3'],
            content['section-5-subsection-2-item-4'],
            content['section-5-subsection-2-item-5']
          ]"
        />
        <CardGrid
          :cards="[
            {
              title: content['section-5-card-1-title'],
              subtitle: content['section-5-card-1-description'],
            },
            {
              title: content['section-5-card-2-title'],
              subtitle: content['section-5-card-2-description'],
            },
            {
              title: content['section-5-card-3-title'],
              subtitle: content['section-5-card-3-description'],
            },
             {
              title: content['section-5-card-4-title'],
              subtitle: content['section-5-card-4-description'],
            },
          ]"
        />
      </div>
    </div>
  <BottomNavigator :order='2'/>
  </Loading>
</template>

<script>
import api from '@/services/api.js';
import Alert from '@/components/Alert.vue'
import BottomNavigator from '@/components/BottomNavigator.vue'
import Card from '@/components/Card.vue';
import CardGrid from '@/components/CardGrid.vue';
import ListCard from '@/components/ListCard.vue';
import ListCardNoButtomPadding from '@/components/ListCardNoButtomPadding.vue';
import ListCardNoTitle from '@/components/ListCardNoTitle.vue';
import SubList from '@/components/SubList.vue';
import Loading from '@/components/Loading.vue';
import Navigation from '@/components/Navigation.vue';
import PageTitle from '@/components/PageTitle.vue';
import SingleCard from "@/components/SingleCard.vue";

export default {
	data: () => ({
		content: {},
		isLoading: false,
		isError: false,
    sectionOne: {
      title:"",
      cards:[],
      cards2:[],
    },
		errorMessage: ''
	}),
	methods: {
		async getData() {
			this.isError = false;
			this.isLoading = true;
			try {
				const { data } = await api.getContent('project-spotlight');
				this.content = data.content;
        this.images = data.images
        this.sectionOne.title = data.content["section-1-subsection-3-description"].replace(/<altColor>/g, '<span class="altColor">').replace(/<\/altColor>/g, '<\/span>')
        this.sectionOne.cards = [
          {
            contentTitle: data.content['section-1-subsection-3-card-1-title'],
            description: data.content['section-1-subsection-3-card-1-description'],
            subtitleBold: data.content['section-1-subsection-3-card-1-subtitle'],
          },
          {
            contentTitle: data.content['section-1-subsection-3-card-2-title'],
            description: data.content['section-1-subsection-3-card-2-description'],
            subtitleBold: data.content['section-1-subsection-3-card-2-subtitle'],
          },
        ], 
         this.guidingValuesCards = [
          {imgSrc: data.images['section-1-subsection-2-value-1-icon.svg'],
            title: data.content['section-1-subsection-3-card2-1-title'],
            paragraph:
              data.content['section-1-subsection-3-card2-1-description']
          },
          {imgSrc: data.images['section-1-subsection-3-value-2-icon.svg'],
             title: data.content['section-1-subsection-3-card2-2-title'],
             paragraph: data.content['section-1-subsection-3-card2-2-description']
          },  
          {imgSrc: data.images['section-1-subsection-3-value-3-icon.svg'],
            title: data.content['section-1-subsection-3-card2-3-title'],
             paragraph: data.content['section-1-subsection-3-card2-3-description']
          },
        ]
			} catch (err) {
				this.isError = true;
				this.errorMessage = err.message;
			} finally {
				this.isLoading = false;
			}
		},
	},

	async beforeRouteUpdate(to, from, next) {
		await this.getData();
		next();
	},

	async created() {
		await this.getData();
	},

	components: {
    BottomNavigator,
		PageTitle,
		Navigation,
    Loading,
		Card,
		ListCard,
    ListCardNoButtomPadding,
    ListCardNoTitle,
		SubList,
		CardGrid,
    Alert,
    SingleCard,
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.theme-container {
  :deep(.altColor){
    color: $alt-font-color-1;
  }
  :deep(.page-title) {
    .title-container{
      @include bg-color( #1D63CF);
;
    }
  }
}
.descriptionCards{
  .descriptionCard{  
      @include responsive(tablet){
        flex: 4 0 100%;
      }
    :deep(.card) {
      padding: 15px;
        border-radius: 0px;
        box-shadow: none;
      .cardHeader{
        color: #34373f;
        letter-spacing: .2rem;
      }
      .subtitleBold{
          font-size: 14px;
          @include color(takeda-dark-grey);
          font-weight: 600;
      }
      .contentText{
          font-size: 14px;
          @include color(slalom-dark-gray);
      }
      .grid-content{
        border-left: 5px solid $border-color-1;
      }
    }
  }
}
.guidingValuesCards{
    @include responsive(phone){
      padding:3em;
    }
  .guidingValuesCard{
    margin: 5px;
    padding: 5px;
    @include responsive(phone){
      flex: 1 0 100%
    }
    @include responsive(tablet){
    }
    :deep(.card)  {
      border-radius: 0px;
      box-shadow: none;
      .cardHeader{
      }
      .img-wrapper{
        margin-bottom:1em;
      }
      .subtitleBold{
        font-size:1.15em;
        line-height:1;
        height: 30px;
      }
      .contentText{
      }
      .grid-cell{
        width: 330px;
        @include responsive(phone){
          width:auto;
        }
      }
      .grid-content{
        padding-left:0px;
        padding-right:0px;
        margin-bottom:1.0em;
      }
    }
  }
}
.sectionContainerTitle {
  @include bg-color(takeda-white);
  @include padding(12 10 0 10);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}
.section-container {
  @include padding(10);
  @include bg-color(takeda-light-grey);
  margin-top: -20px;
  margin-bottom: 100px;
  z-index: 1;
    @include responsive(phone) {
    @include padding(10 1 10 1)
  }
  @include responsive(tablet) {
    @include padding(10 5 10 5);
  }
}  
</style>
